import { decorate, observable, action } from "mobx";
import { create, persist } from "mobx-persist";

class ContractCustomerStore {
    customerLastName = "";
    customerNationalId = "";
    customerDateOfBirth = "";
    deviceMake = "";
    deviceModel = "";
    gender = "";
    email = "";
    phoneNumber = "";
    address = "";
    imei = "";
    purchasePrice = "";

    updateContractCustomerDetail = customerDetail => {
        this.customerLastName = customerDetail.lastName;
        this.customerNationalId = customerDetail.id;
        this.customerDateOfBirth = customerDetail.dateOfBirth;
        this.deviceMake = customerDetail.deviceMake;
        this.deviceModel = customerDetail.deviceModel;
        this.gender = customerDetail.gender;
        this.email = customerDetail.email;
        this.phoneNumber = customerDetail.phoneNumber;
        this.address = customerDetail.address;
        this.imei = customerDetail.imei;
        this.purchasePrice = customerDetail.purchasePrice;
    }

}

decorate(ContractCustomerStore, {
    customerLastName: [persist, observable],
    customerDateOfBirth: [persist, observable],
    customerNationalId: [persist, observable],
    deviceMake: [persist, observable],
    deviceModel: [persist, observable],
    gender: [persist, observable],
    email: [persist, observable],
    phoneNumber: [persist, observable],
    address: [persist, observable],
    imei: [persist, observable],
    purchasePrice: [persist, observable],
    updateContractCustomerDetail: action,
});
var contractCustomerStore = new ContractCustomerStore();
export default contractCustomerStore;

if (typeof window !== "undefined") {
    const hydrate = create({
        storage: localStorage,
        jsonify: true,
    });
    hydrate("contractCustomerStore", contractCustomerStore);
}
