import React, { useState, useEffect } from "react";

import api from "../../../service/api";
import contractCustomerStore from "../../../store/contractCustomerStore";
import alertStore from "../../../store/alertStore";
import errorTranslationKeyStore from "../../../store/errorTranslationKeyStore";
import AppLayout from "../../../components/shared/AppLayout";
import companyStamp from "../../../assets/images/terms-and-conditions/company-stamp.png";
import { getGenderByNationlId, sanitizeError, errorKeyNavigateSystemErrorPage } from "../../../app.utils";

const PageTermsAndConditionsReport = () => {
    const customerLastName = contractCustomerStore.customerLastName;
    const customerDateOfBirth = contractCustomerStore.customerDateOfBirth;
    const customerNationalId = contractCustomerStore.customerNationalId;
    const customerGender = getGenderByNationlId(contractCustomerStore.customerNationalId);
    const [ devicePlanPrice, setDevicePlanPrice ] = useState();

    const onHandleGetDevicePlanPrice = async () => {
        const payload = {
            countryCode: "TW",
            partnerCode: "hotai",
            planType: "premium",
            deviceMake: contractCustomerStore.deviceMake,
            deviceModel: contractCustomerStore.deviceModel
        };
        try {
            const response = await api.post.contractDevicePlanPrice(payload);
            setDevicePlanPrice(response?.data?.planPrice);
        } catch (error) {
            const errorTranslationKey = error?.response?.data?.translationKey;

            alertStore.updateAlertMessage(sanitizeError(error));
            if (errorTranslationKey) {
                errorTranslationKeyStore.updateTranslationKey(errorTranslationKey);
                errorKeyNavigateSystemErrorPage(errorTranslationKey);
            }
        }
    };

    useEffect(() => {
        onHandleGetDevicePlanPrice();
    }, []);

    return (
        <AppLayout hasHeader={true} isBackBtn={true} hasFooter={true}>
            <div className="app-page page-terms-and-conditions">

                <div className="terms-and-conditions">

                    <div className="container">

                        <p className="terms-and-conditions__title">
                            <strong>保特保經書面分析報告</strong>
                        </p>

                        <div className="terms-and-conditions__wrapper">

                            <p>
                                <b>(一) 基本資料</b><br/>
                                <b>要保人:</b> {customerLastName}<br/>
                                <b>被保人:</b> {customerLastName}<br/>
                                <b>性別:</b> {customerGender}<br/>
                                <b>出生年月日:</b> {customerDateOfBirth}<br/>
                                <b>身分證字號:</b> {customerNationalId}<br/>
                            </p>

                            <p>
                                <b>(二) 保險需求</b><br/>
                                <b>本次投保目的及需求:</b> 保障<br/>
                                <b>是否有指定之保險公司？</b> 是，明台產險<br/>
                                <b>欲投保之保險種類:</b> 行動裝置保險<br/>
                                <b>保險期間:</b> 兩年期<br/>
                                <b>欲投保之保險金額:</b> 行動裝置售價<br/>
                                <b>是否已有投保其他商業保險之有效保險契約(同類保險):</b> 否<br/>
                                <b>預估繳交保險費金額:</b> 新台幣 {devicePlanPrice} 元<br/>
                            </p>

                            <p>
                                <b>(三)本保險經紀人公司根據要保人/被保人所提供各種資訊及需求之建議內容</b><br/>
                                <b>保險公司名稱及概況:</b> 明台產險 <a href="https://www.msig-mingtai.com.tw" target="_blank" rel="noreferrer">https://www.msig-mingtai.com.tw</a><br/>
                                <b>保險險種名稱:</b> 行動裝置保險<br/>
                                <b>保險金額及保障範圍:</b><br/>
                                <b>1.</b> 裝置因不可預料之事故所致毀損、碾壓、破裂或泡水時，可以負擔自負額後進行維修；原機無法維修或維修費用高於原機空機價時，以負擔自負額後置換方式處理<br/>
                                <b>2.</b> 意外損壞理賠(維修+置換): 二年一次<br/>
                                <br/>
                                <b>保險費:</b> 新台幣 {devicePlanPrice} 元<br/>
                                <br/>
                                <b>建議投保保險公司理由:</b> 客戶指定<br/>
                            </p>

                            <p>
                                <b>備註</b><br/>
                                本分析報告並非保險契約之一部分，本分析報告所建議內容，保險公司是否予以承保及詳細保險商品內容、費率及變更，需以要保文件及投保當時保單條款和保險公司作業規則為準。<br/>
                                <br/>
                                本分析報告係依據要、被保險人所提供各種資訊與需求所製作，並按製作時所可得之資訊而作出，本保險經紀人公司無法保證或承諾本分析報告內容完全正確無誤，抑或保險契約訂立後，將不因法令變更、金融保險市場變化或新金融保險相關資訊之衍生而有所變化或影響。<br/>
                                <br/>
                                <b>保險經紀人姓名/執業證照編號:</b>
                                <img src={companyStamp} alt="" /> <b>/ 110-ELBP000062</b><br/>
                            </p>

                            <p>
                                <b>保險經紀人公司/保險經紀人營業所在地：台北市大安區忠孝東路4段107號12樓</b>
                            </p>

                        </div>

                    </div>

                </div>

            </div>
        </AppLayout>
    );
};

export default PageTermsAndConditionsReport;
